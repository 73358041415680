import React, {useEffect, useRef, useState} from 'react';
import WalletConnect from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import {Contract, ethers} from 'ethers';
import dotenv from 'dotenv';
import './Application.css';
import IceLogo from './ice-logo.svg';
import VerticalLine1 from './vertical-line-1.svg';
import DescriptionIcon from './description-icon.svg';
import IceOpenNetworkLogo from './ice-open-network-logo.svg';
import {ReactComponent as MemeIcon} from './meme-markers.svg';
import {ReactComponent as BridgeIcon} from './bridge-icon.svg';
import {ReactComponent as PlusIcon} from './plus-icon.svg';
import {ReactComponent as WalletIcon} from './wallet-icon.svg';
import {ReactComponent as DropIcon} from './drop-icon.svg';
import {ReactComponent as DisconnectIcon} from './disconnect-icon.svg';
import {ReactComponent as CompletedIcon} from './completed-icon.svg';
import {ReactComponent as PendingIcon} from './pending-icon.svg';
import {ReactComponent as FailedIcon} from './failed-icon.svg';
import {ReactComponent as BinanceIcon} from './binance-icon.svg';
import ThousandsNumberInput from "./thousands-number-input";

dotenv.config();

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const providerOptions = {
    walletconnect: {
        package: WalletConnect,
        options: {
            infuraId: process.env.REACT_APP_INFURA_KEY
        }
    }
};

// Define the ABI for ICEToken (ERC20 standard) and IONSwap contracts
const ICETokenABI = [
    // Include the `approve` and `balanceOf` functions from ERC20 standard
    "function approve(address spender, uint256 amount) external returns (bool)",
    "function balanceOf(address account) external view returns (uint256)",
];

const IONSwapABI = [
    // Include the `swapTokens` function
    "function swapTokens(uint256 amount) external",
];

function Application() {

    const [iceAmount, setIceAmount] = useState('1');
    const [isConnected, setIsConnected] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [accountAddress, setAccountAddress] = useState('');
    const [transactionHash, setTransactionHash] = useState(''); // State to hold the transaction hash
    const [swapStatus, setSwapStatus] = useState([]); // State to hold the swap steps and their statuses
    const [isSwapping, setIsSwapping] = useState(false); // State to indicate if swapping is in progress
    const [isDisconnectMenuVisible, setIsDisconnectMenuVisible] = useState(false); // State for disconnect menu visibility
    const [hasEnoughICE, setHasEnoughICE] = useState(true);

    // Use useRef to hold the Web3Modal instance, provider, and menu ref
    const web3ModalRef = useRef(null);
    const providerRef = useRef(null);
    const menuRef = useRef(null);

    // Determine if the app is running on testnet based on the REACT_APP_TESTNET flag
    const isTestnet = process.env.REACT_APP_TESTNET === '1';

    // Initialize Web3Modal on component mount
    useEffect(() => {
        web3ModalRef.current = new Web3Modal({
            providerOptions,
            cacheProvider: true, // Enable caching to remember connection
        });

        // Automatically connect if the provider is cached
        if (web3ModalRef.current.cachedProvider) {
            connectWallet().then(() => {
                setIsLoaded(true);
            });
        } else {
            setIsLoaded(true);
        }
    }, []);

    const calculateHasEnoughICE = async (value) => {

        if (!accountAddress || !providerRef.current) {
            return;
        }

        const ethersProvider = new ethers.BrowserProvider(providerRef.current);
        const iceTokenContract = new Contract(process.env.REACT_APP_ICE_TOKEN_CONTRACT_ADDRESS, ICETokenABI, ethersProvider);

        // Get the balance of the current account
        const balance = await iceTokenContract.balanceOf(accountAddress);

        // Remove commas from the entered value and default to '0' if empty
        const cleanedValue = value.replace(/,/g, '') || '0';

        // Convert the entered value to a BigInt (assuming ICE token has 18 decimals)
        const valueInWei = ethers.parseUnits(cleanedValue, 18);

        return valueInWei <= balance;
    };

    const handleIceAmountChange = (value) => {
        calculateHasEnoughICE(value).then(setHasEnoughICE);
        setIceAmount(value);
    };

    const setMaxIceAmount = async () => {
        if (!accountAddress || !providerRef.current) {
            return;
        }

        const ethersProvider = new ethers.BrowserProvider(providerRef.current);
        const iceTokenContract = new Contract(process.env.REACT_APP_ICE_TOKEN_CONTRACT_ADDRESS, ICETokenABI, ethersProvider);

        // Get the balance of the current account
        const balance = await iceTokenContract.balanceOf(accountAddress);

        // Convert the balance from Wei to decimal string
        const balanceInEtherString = ethers.formatUnits(balance, 18);

        let balanceFixed = Math.floor(Number(balanceInEtherString));
        balanceFixed = `${balanceFixed}`;
        setIceAmount(balanceFixed);
        calculateHasEnoughICE(balanceFixed).then(setHasEnoughICE);
    };

    const handleSwap = async () => {

        const amount = iceAmount.replace(',', '');

        if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
            alert("Please enter a valid amount of ICE to swap.");
            return;
        }

        // Initialize swap steps
        const initialSwapStatus = [
            {name: 'Approving ICE tokens', status: 'pending'},
            {name: 'Swapping ICE for ION', status: 'pending'},
            {name: 'Transaction submitted', status: 'pending'},
            {name: 'View on BscScan', status: 'binance'},
        ];
        setTransactionHash(undefined);
        setSwapStatus(initialSwapStatus);
        setIsSwapping(true);

        try {
            const ethersProvider = new ethers.BrowserProvider(providerRef.current);
            const signer = await ethersProvider.getSigner();

            // Define the contract addresses from environment variables
            const ICETokenAddress = process.env.REACT_APP_ICE_TOKEN_CONTRACT_ADDRESS;
            const IONSwapAddress = process.env.REACT_APP_ION_SWAP_CONTRACT_ADDRESS;

            if (!ICETokenAddress || !IONSwapAddress) {
                console.error("Contract addresses are not defined in environment variables.");
                return;
            }

            // Create contract instances
            const iceTokenContract = new Contract(ICETokenAddress, ICETokenABI, signer);
            const swapContract = new Contract(IONSwapAddress, IONSwapABI, signer);

            // Amount to swap, converted to Wei (assuming ICE token has 18 decimals)
            const amountToSwap = ethers.parseUnits(amount, 18);

            // Step 1: Approve the IONSwap contract to spend ICE tokens on behalf of the user
            updateSwapStatus('Approving ICE tokens', 'in-progress');
            const approveTx = await iceTokenContract.approve(IONSwapAddress, amountToSwap);
            // Wait for the transaction to be mined
            await approveTx.wait();
            updateSwapStatus('Approving ICE tokens', 'completed');

            // Step 2: Call the swapTokens method on the IONSwap contract
            updateSwapStatus('Swapping ICE for ION', 'in-progress');
            const swapTx = await swapContract.swapTokens(amountToSwap);
            updateSwapStatus('Swapping ICE for ION', 'completed');

            // Wait for the transaction to be mined
            updateSwapStatus('Transaction submitted', 'in-progress');
            await swapTx.wait();
            updateSwapStatus('Transaction submitted', 'completed');

            // Save the transaction hash
            setTransactionHash(swapTx.hash);

            // Inform the user
            // alert(`Successfully swapped ${amount} ICE for ION.\nTransaction Hash: ${swapTx.hash}`);

            // Clear the input
            setIceAmount('');
        } catch (error) {

            console.error(error);

            // Reset swap statuses on error
            setSwapStatus([
                {name: capitalize(error.shortMessage), status: 'failed'},
            ]);

            updateSwapStatus(capitalize(error.shortMessage), 'failed');
        } finally {
            setIsSwapping(false);
        }
    };

    // Function to update the swap status of a step
    const updateSwapStatus = (stepName, status) => {
        setSwapStatus(prevStatus => {
            return prevStatus.map(step => {
                if (step.name === stepName) {
                    return {...step, status};
                }
                return step;
            });
        });

        // Set timeout to remove the `completed` notification after 11 seconds
        if (status === 'completed' || status === 'failed') {
            setTimeout(() => {
                setSwapStatus(prevStatus => prevStatus.filter(step => step.name !== stepName));
            }, 11000);
        }
    };

    const connectWallet = async () => {
        try {
            const provider = await web3ModalRef.current.connect();
            providerRef.current = provider; // Save the provider for later use
            setIsConnected(true);

            // Get accounts and set the account address
            const accounts = await provider.request({method: 'eth_accounts'});
            if (accounts.length > 0) {
                setAccountAddress(accounts[0]);
            }

            provider.on("accountsChanged", (accounts) => {
                if (accounts.length === 0) {
                    // User disconnected their wallet
                    setIsConnected(false);
                    setAccountAddress('');
                } else {
                    setAccountAddress(accounts[0]);
                }
                // Hide the disconnect menu when account changes
                setIsDisconnectMenuVisible(false);
            });

            provider.on("disconnect", () => {
                setIsConnected(false);
                setAccountAddress('');
                setIsDisconnectMenuVisible(false);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const showDisconnectMenu = () => {
        setIsDisconnectMenuVisible(!isDisconnectMenuVisible);
    };

    const disconnectWallet = async () => {
        // Clear cached provider to forget the connection
        await web3ModalRef.current.clearCachedProvider();
        if (providerRef.current && providerRef.current.disconnect) {
            await providerRef.current.disconnect();
        }
        providerRef.current = null;
        setIsConnected(false);
        setAccountAddress('');
        setIsDisconnectMenuVisible(false);
    };

    // Close the disconnect menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsDisconnectMenuVisible(false);
            }
        };
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    // Helper function to shorten the address
    const shortenAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 5)}...${address.slice(-5)}`;
    };

    function openBridge() {
        window.open(process.env.REACT_APP_BRIDGE_URI, '__empty');
    }

    // Define a helper function to render notification messages
    const renderNotifications = () => {
        if (swapStatus.length === 0) return null;

        return swapStatus.map((step, index) => {
            // Skip rendering if status is 'binance' and no transactionHash
            if (step.status === 'binance' && !transactionHash) {
                return null;
            }

            return (
                <div key={index} className={`notification step-${step.status}`}>
                    <div>
                        {step.status === 'completed' && <CompletedIcon className="notification-status-icon"/>}
                        {(step.status === 'in-progress' || step.status === 'pending') &&
                            <PendingIcon className="notification-status-icon"/>}
                        {step.status === 'failed' && <FailedIcon className="notification-status-icon"/>}
                        {step.status === 'binance' && transactionHash &&
                            <BinanceIcon className="notification-status-icon"/>}
                        {step.status !== 'binance' && step.name}
                        {step.status === 'binance' && transactionHash && <a
                            href={`${isTestnet ? 'https://testnet.bscscan.com' : 'https://bscscan.com'}/tx/${transactionHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{step.name}</a>}
                    </div>
                </div>
            )
        });
    };

    return (
        <div className="Application">

            <div className="notifications-area">
                {renderNotifications()}
            </div>

            <div className="menu">

                <img src={IceOpenNetworkLogo} alt="Ice Open Network"/>

                <div className="tabs">
                    <button className="swap-tab">
                        <MemeIcon className="meme-icon-blue"/>
                        Swap
                    </button>

                    <button className="bridge-tab" onClick={openBridge}>
                        <BridgeIcon className="bridge-icon-gray"/>
                        Bridge
                    </button>
                </div>

                {isLoaded ? (
                    <div className="connect-wallet-container" ref={menuRef}>
                        {isConnected ? (
                            <>
                                <button className="connect-wallet-button" onClick={showDisconnectMenu}>
                                    <WalletIcon/>
                                    {shortenAddress(accountAddress)}
                                    <DropIcon className="drop-icon"/>
                                </button>

                                {isDisconnectMenuVisible && (
                                    <button className="disconnect-wallet-button" onClick={disconnectWallet}>
                                        <DisconnectIcon/>
                                        Disconnect
                                    </button>
                                )}
                            </>
                        ) : (
                            <button className="connect-wallet-button" onClick={connectWallet}>
                                <PlusIcon/>
                                Connect wallet
                            </button>
                        )}
                    </div>
                ) : (
                    <div/>
                )}
            </div>

            <div className="swap-form">
                <h1>Swap ICE for ION</h1>
                <div className={`form-group form-group-1 ${!hasEnoughICE ? 'insufficient-balance' : ''}`}>
                    <div className="input-field">
                        <img src={IceLogo} className="token" alt="ICE"/>
                        <img src={VerticalLine1} className="vertical-line-1" alt=""/>
                        <div>
                            <span className='normal'>Enter ICE amount</span>
                            <span className='alert'>Insufficient ICE balance</span>
                            <ThousandsNumberInput
                                initialValue={iceAmount}
                                onChange={handleIceAmountChange}
                                disabled={isSwapping}
                            />
                        </div>
                        <span className="max" onClick={setMaxIceAmount}>MAX</span>
                    </div>
                </div>
                <div className="form-group form-group-2">
                    <div className="input-field">
                        <img src={IceLogo} className="token" alt="ION"/>
                        <img src={VerticalLine1} className="vertical-line-1" alt=""/>
                        <div>
                            <span>You receive</span>
                            <ThousandsNumberInput initialValue={iceAmount} readOnly={true} suffix=" ION"/>
                        </div>
                    </div>
                </div>
                <button className="swap-button" onClick={handleSwap} disabled={!isConnected || isSwapping}>
                    <MemeIcon className="meme-icon-white"/>
                    {isSwapping ? 'Swapping...' : 'Swap'}
                </button>
            </div>

            <div className="description-form">
                <img src={DescriptionIcon} alt="Help"/>
                <div>
                    <h2>How does it work?</h2>
                    <span>If you have any questions, then check out our detailed guide on how it works</span>
                </div>
            </div>
        </div>
    );
}

export default Application;
