import {useEffect, useState} from "react";

const ThousandsNumberInput = ({
                                  initialValue = "0",
                                  readOnly = false,
                                  disabled = false,
                                  suffix = '',
                                  onChange
                              }) => {
    const [value, setValue] = useState(initialValue);

    const addCommas = (num) =>
        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const removeNonNumeric = (num) =>
        num.toString().replace(/[^0-9]/g, "");

    useEffect(() => {
        setValue(addCommas(initialValue));
    }, [initialValue]);

    const handleChange = (event) => {
        const formattedValue = addCommas(removeNonNumeric(event.target.value));
        setValue(formattedValue);
        onChange?.(formattedValue);
    };

    const displayValue = readOnly && suffix && value.length > 0 ? `${value}${suffix}` : value;

    return (
        <input
            type="text"
            value={displayValue}
            onChange={handleChange}
            readOnly={readOnly}
            disabled={disabled}
        />
    );
};

export default ThousandsNumberInput;
